<action-box *ngIf="tab.title"  class="detail-item tab-detail {{extraStyles}}" [isIconLeftVault]="isVault" iconLeft="{{icon}}" textLeft="{{tab.title}}">
  <gridview *ngIf="contentVisible" >
    <gridview-row *ngFor="let row of rows"
                  classes="row-data">
      <columntemplate *ngFor="let list of row.cells" classes="min-width col-12 col-sm-6" [class.blink]="list.longId === activeParamId"  >
        <summary-param-detail *ngIf="list.backgroundImage != 'attributedecoration'; else attributeDetail" [list]="list" ></summary-param-detail>
        <ng-template #attributeDetail>
          <list [list]="list" (mouseenter)="openAttributePopup(list)" (click)="openAttributePopup(list)"></list>
        </ng-template>
      </columntemplate>
    </gridview-row>
  </gridview>
  <div card-type="control" *ngIf="isPrintView">
    <print-checkbox (onValueChange)="onPrintValueChange($event)"></print-checkbox>
  </div>
</action-box>